import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import useForm from "../hooks/useForm";
import validate from "../helper/validateDeal";
import PhoneInput from "react-phone-input-2";
import tr from "react-phone-input-2/lang/tr.json";
import de from "react-phone-input-2/lang/de.json";
import { Button, TextField } from "@material-ui/core";
import { navigate } from "gatsby-link";
import { makeStyles } from "@material-ui/core/styles";
import { getLanguage, getData } from "../helper/helper";
import "react-phone-input-2/lib/material.css";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& label.Mui-focused": {
      color: "#3c3c3c",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#c0855c",
    },
  },
  textField: {
    margin: "10px 0 0 0 !important",
    width: "100%",
    backgroundColor: "#fff",
  },
  button: {
    margin: "20px 0",
    backgroundColor: "#c0855c",
    minHeight: "44px",
    boxShadow: "none",
    border: "solid 1px #c0855c",
    borderRadius: "0",
    "& .MuiButton-label": {
      fontWeight: "600",
      letterSpacing: "3px",
    },
    "&:hover": {
      backgroundColor: "#fff",
      boxShadow: "none",
    },
  },
}));

const DealForm = ({ title, description, sendfrom }) => {
  const classes = useStyles();
  const locale = getLanguage();
  const book = getData(locale).bookForm;
  const form = useRef();
  const [style, setStyle] = useState("");
  const action = locale === "en" ? "/contact/thanks/" : `/${locale}/contact/thanks/`;
  const { handleChange, handleSubmit, values, errors } = useForm(
    submitForm,
    validate
  );

  function submitForm() {
    setStyle("loader");
    emailjs
      .sendForm(
        "service_fq53z8p",
        "zen_special_deal_form",
        form.current,
        "user_z2pzeOzhloym5wVKqR123"
      )
      .then(
        (result) => {
          console.log(result.text);
          navigate(form.current.getAttribute("action"));
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  const fullPath = typeof window !== "undefined" && window.location.href;

  
  const lang = () => {
    switch (locale) {
      case "tr":
        return tr;
      case "de":
        return de;
  
      default:
        return null;
    }
  };


  return (
    <form
      className={classes.container + ` contactForm deal`}
      method="POST"
      action={action}
      onSubmit={handleSubmit}
      ref={form}
      noValidate
    >
      <h3>{title}</h3>
      <p>{description}</p>
      <p className="hidden">
        <input type="hidden" name="send_from" value={sendfrom} />
        <input type="hidden" name="deal" value={title + ` - ` + description} />
        <input type="hidden" name="url" value={fullPath} />
      </p>
      <TextField
        error={errors.name ? true : false}
        id="name"
        label={book.name}
        name="name"
        defaultValue={values.name}
        onChange={handleChange}
        helperText={
          errors.name && <p style={{ color: "red" }}>{errors.name}</p>
        }
        variant="outlined"
      />
      <div className="phone-fields">
        <PhoneInput
          containerStyle={{ marginBottom: "10px" }}
          localization={lang}
          specialLabel={book.phone}
          country={locale === "tr" ? "tr" : "de"}
          value={values.phone}
          placeholder={book.phone}
          name="phone"
          enableSearch
          onChange={handleChange}
          inputProps={{
            required: true,
            name: "phone",
          }}
        />
        {errors.phone && (
          <p style={{ color: "red", marginLeft: "15px" }}>{errors.phone}</p>
        )}
      </div>
      <Button variant="contained" type="submit" className={classes.button}>
        <span className={style}></span>
        {book.button}
      </Button>
    </form>
  );
};

export default DealForm;
