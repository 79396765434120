import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { getLanguage, getData } from "../helper/helper";
import Img from "gatsby-image";

const RelatedBody = () => {
  const locale = getLanguage();
  const body = getData(locale).bodyPage;
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query RelatedBody($locale: String) {
        allMarkdownRemark(
          limit: 25
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              pageKey: { eq: "page_bodypost" }
              locale: { eq: $locale }
            }
          }
        ) {
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              fields {
                slug
              }
              frontmatter {
                title
                locale
                pageKey
                category
                description
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 350, quality: 85) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return allMarkdownRemark.edges
    .filter(({ node: post }) => post.frontmatter.locale === locale)
    .slice(0, 4)
    .map(({ node: post }) => (
      <Link
        className="column"
        key={post.fields.slug}
        to={`${post.fields.slug}`}
        title={post.frontmatter.title}
      >
        <div className="cover">
          <Img
            fluid={post.frontmatter.featuredimage.childImageSharp.fluid}
            alt={post.frontmatter.title}
            title={post.frontmatter.title}
            className="image"
          />
          <div className="content">
            <h4>{post.frontmatter.title}</h4>
            <p>{post.frontmatter.description}</p>
          </div>
          <span>
            <p>{body.button}</p>
          </span>
        </div>
      </Link>
    ));
};

export default RelatedBody;
