import { getLanguage } from "./helper";
export default function validateInfo(values) {
  const locale = getLanguage();

  let errors = {};

  const error =
    locale === "en"
      ? "This field is required"
      : locale === "de"
      ? "Dieses Feld ist erforderlich"
      : "Bu alan gereklidir";
  const errorName =
    locale === "en"
      ? "Enter a valid name"
      : locale === "de"
      ? "Geben Sie einen gültigen Namen ein"
      : "Lutfen gecerli bir isim giriniz";
  const errorPhone =
    locale === "en"
      ? "Phone number needs to be 7 characters or more"
      : locale === "de"
      ? "Die Telefonnummer muss mindestens 7 Zeichen lang sein"
      : "Telefon numaraniz en az 7 karakterli olmalidir";

  if (!values.name) {
    errors.name = error;
  } else if (!/^[A-Za-z]+/.test(values.name.trim())) {
    errors.name = errorName;
  }

  if (!values.phone) {
    errors.phone = error;
  } else if (values.phone.length < 9) {
    errors.phone = errorPhone;
  }

  return errors;
}
